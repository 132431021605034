.containerStyles {
  width: 100%;
  height: 50vh;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sliderStyles {
  width: 100%;
  height: 100%;
  position: relative;
}

.slideStyles {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-position: center;
  background-size: cover;
}

.leftArrowStyles,
.rightArrowStyles {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  font-size: 80px;
  color: #5b0e07;
  z-index: 1;
  cursor: pointer;
}

.rightArrowStyles {
  right: 32px;
}

.leftArrowStyles {
  left: 32px;
}

.dotsContainerStyles {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
}

.dotStyles {
  margin: 0 3px;
  cursor: pointer;
  font-size: 15px;
  position: relative;
}

.dotStyles::before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  border: 3px solid;
  border-color: #5b0e07;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: background-color 0.3s;
  background-color: transparent;
}

.dotStyles.active::before {
  background-color: #5b0e07;
  border-color: white;
}

@media only screen and (max-width: 992px) {
  .containerStyles {
      height: 30vh;
      align-items: center;
      justify-content: center;
    }

    .slideStyles {
      border-radius: 0px;
      background-position: center center;
    }

    .leftArrowStyles,
    .rightArrowStyles {
      font-size: 25px;
    }
}

  .contenedor-texto-1 h2 {
    color: #b21919;
    background-color: #fff;
    margin-top: 10px;
    margin-left: 0px;
    margin-bottom: 20px;
    padding: 5px; 
    width: 25%;
    text-align: center;
    border: 2px solid #b21919; 
    border-radius: 0;
    border-width: 1px;
    display: flex;
    flex-direction: column;
    font-size: 25px;
  }
  
  .contenedor-texto-1 p {
    color: #fff;
    margin-left: 40px;
    width: 70%;
    font-size: 17px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
  }

  .contenedor-texto-2 {
    display: flex;
    flex-direction: column;
  }

  .contenedor-texto-2 h2 {
    color: white;
    background-color: #b21919;
    padding: 5px;
    width: 25%;
    margin-left: 0px;
    margin-top: 5px;
    margin-bottom: 20px;
    border: 2px solid #b21919;
    border-radius: 0;
    border-width: 1px;
    font-size: 25px;
    text-align: center;
    display: flex;
    flex-direction: column;
  }
  
  .contenedor-texto-2 p {
    color: #fff;
    margin-left: 40px;
    width: 40%;
    font-size: 17px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
  }

  .right-imagen {
    margin-left: 40px;
    width: 380px;
    height: 30px;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-left: 720px;
    bottom: 360px;
  }

  .Body-text hr {
    border: none;
    height: 1px;
    background-color: white;
    margin: 20px auto;
    width: 70%; 
    margin-left: 20px;
  }
  .imagen-container-2 {
    margin-left: 40px;
    width: 65%;
    height: 60%;
    display: flex;
    flex-direction: column;
  }

  .Body-text {
    max-width: 1700px;
    height: auto;
    margin: 0 auto;
    padding: 50px;
    display: flex;
    flex-direction: column;
  }

  @media only screen and (max-width: 992px) {
    .contenedor-texto-1 h2,
    .contenedor-texto-2 h2 {
      padding: 5px; 
      width: 85%;
      display: flex;
      flex-direction: column;
      font-size: 20px;
      margin: auto;
      text-align: center;
      margin-bottom: 25px;
    }
    .right-imagen {
      margin-left: 0;
      width: 100%;
      height: auto;
      margin-top: 20px;
      position: relative;
      bottom: 0;
    }
    .imagen-container-2{
      width: 100%;
      height: auto;
      margin: 0 auto;
      text-align: center;
    }
    .Body-text hr {
      margin: 20px auto;
      width: 100%; 
    }
    .contenedor-texto-1 p,
    .contenedor-texto-2 p {
      width: 100%;
      text-align: center;
      margin: 0 auto;
    }
  }

.tournament-card {
  position: absolute;
  right: 60px;
  bottom: -170px;
  background-color: #330400;
  color: black;
  border-radius: 0px;
  overflow: hidden;
  width: 300px;
  margin-left: auto;
  margin-right: 200px;
  display: flex;
  flex-direction: column;
}

.torneos {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FF140080;
  color: white;
  padding: 2px;
  font-size: 28px;
}

.header {
  background-color: rgba(255, 255, 255, 0.85);
  padding: 5px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.arrow {
  font-size: 24px;
  color: black;
  cursor: pointer;
}

.header h3 {
  margin: 0;
  line-height: 35px;
}

.subtitle {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #33040080;
  padding: 1px;
  margin-top: 5px;
  white-space: nowrap;
  font-size: 15px;
}

.subtitle button {
  background-color: #33040080;
  border: 2px solid white;
  padding: 5px 61px;
  color: white;
  cursor: pointer;
  outline: none;
}

.subtitle button:hover {
  background-color: darkgrey;
}

.matches {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  padding: 5px;
}

.match {
  display: flex;
  align-items: center;
}

.match img {
  width: 60px;
  margin-right: 10px;
  margin-left: 10px;
}

.match div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.match span.result {
  color: white;
  font-size: 45px;
  margin: 0 5px;
}

.match span.date {
  color: white;
  font-size: 14px;
  text-align: center;
}

.match-divider {
  border: none;
  border-top: 1px solid rgba(255, 255, 255, 1);
  margin: 10px 0;
  width: 100%;
}

.footer {
  padding: 1px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.5);
}

.footer button {
  background-color: #33040080;
  border: 2px solid white;
  padding: 0px 105px;
  color: white;
  cursor: pointer;
  outline: none;
}

.footer button:hover {
  background-color: darkgrey;
}

@media only screen and (max-width: 992px) {
  .tournament-card{
    position: static;
    height: auto;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 80px;
  }
}

.card {
  background-color: #ffcccc;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: auto;
  margin-top: 40px;
  margin-bottom: 40px;
}

.card label,
.card input,
.card button,
.card p {
  color: black;
  display: block;
  width: 100%;
  margin-bottom: 10px;
}
.countrySelector{
  margin-bottom: 10px;
}

.card input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  color: black;
}

.card button {
  background-color: #4caf50;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
}

.card button {
  color: #fff;
}

.card button:hover {
  opacity: 0.8;
}

.card h2 {
  text-align: center;
}

.card-token {
  background-color: #ffff;
  border-radius: 8px;
  padding: 30px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  max-width: 600px;
  margin: auto;
  margin-top: 40px;
  margin-bottom: 600px;
}

@media (max-width: 992px) {
  .card, .card-token {
    max-width: 90%;
  }
}

.login-card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 90%;
  max-width: 700px;
  border-radius: 5px;
  padding: 20px;
  margin: auto;
  margin-top: 100px;
  margin-bottom: 235px;
  background-color: white;
  display: flex;
  flex-direction: column;
}

.login-card input[type="email"],
.login-card input[type="password"] {
  width: calc(100% - 20px);
  padding: 10px;
  margin: 10px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
  border-radius: 5px;
}

.login-card button {
  background-color: #4caf50;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
  border-radius: 5px;
}

.login-card button:hover {
  opacity: 0.8;
}


.checkbox-container input[type="checkbox"] {
  margin-right: 10px;
}

.login-card label {
  margin: 0;
}

.banner {
  position: relative;
  height: 400px;
  overflow: hidden;
}

.banner-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-picture {
  position: absolute;
  left: 100px;
  width: 300px;
  height: 300px;
  margin-top: 45px;
  border-radius: 50%;
  overflow: hidden;
  border: 5px solid #5B0E07;
  z-index: 1;
}

.profile-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mini-navbar {
  background-color: #FFFFFF;
  margin-bottom: 50px;
  height: 50px;
}

.mini-navbar ul {
  list-style: none;
  display: flex;
  height: 100%;
  margin: 0;
  padding: 0;
  margin-left: 50px;
}

.mini-navbar li {
  margin-right: 20px;
  display: flex;
  align-items: center;
  height: 100%;
}

.mini-navbar .nav-link {
  background-color: transparent;
  color: #5B0E07;
  border: none;
  cursor: pointer;
  font-size: 16px;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 0 20px;
  height: 100%;
}

.mini-navbar .nav-link:hover {
  color: #FFFFFF;
  background-color: #330400;
}

.nav-link.active {
  background-color: #330400;
  color: #FFFFFF;
}

.upload-btn {
  position: absolute;
  border: none;
  background-color: rgba(0, 0, 0, 0.7);
  width: 80px;
  height: 80px;
  border-radius: 60%;
  font-size: 40px;
  display: none;
  cursor: pointer;
  line-height: 20px;
}

.profile-picture:hover .upload-btn,
.banner:hover .upload-btn {
  display: block;
}

.upload-input {
  display: none;
}

.banner .upload-btn {
  right: 50px;
  top: 20px;
}

.profile-picture .upload-btn{
  right: 100px;
  bottom: 10px;
}

.players-container{
  display: flex;
  flex-direction: column;
}

.member-number {
  margin-right: 10px;
  color: white;
}

.member-info {
  display: flex;
  align-items: center;
}

.team-member {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.team-member > div, .team-member > button {
  margin-right: 10px;
}

.nombre-real, .nickname {
  background-color: white;
  border-radius: 5px;
  padding: 5px 10px;
  color: #5B0E07;
  width: 300px;
  margin: 10px;
}

.membresia {
  color: white;
  border-radius: 5px;
  padding: 5px 10px;
  width: 300px;
  text-align: center;
  margin: 10px;
}

.activada {
  color: green;
  background-color: white;
  font-weight: bold;
}

.desactivada {
  color: #5B0E07;
  background-color: white;
  font-weight: bold;
}

.btn-eliminar {
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #5B0E07;
  color: white;
  margin: 10px;
}

.btn-eliminar:hover {
  background-color: darkred;
}

.btn-aceptar {
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: green;
  color: white;
}

.btn-aceptar:hover {
  background-color: darkgreen;
}

.integrantes-label {
  font-weight: bold;
  margin-right: 5px;
  color: white;
}

.membresia-label{
  font-weight: bold;
  margin-right: 5px;
  color: white;
  margin-left: 580px;
}

.nombre-real, .membresia {
  display: inline-block;
}

.playerSelector label {
  color: white;
  margin: 10px;
  width: 100%;
  margin-left: 50px;
}

.playerSelector{
  width: 400px;
}

.invite-container{
  width: 500px;
  margin-left: 50px;
  margin-bottom: 140px;
}

.inviteButton{
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  margin-top: 15px;
  margin-bottom: 25px;
  padding: 10px 20px;
  cursor: pointer;
}
.inviteButton:hover{
  opacity: 0.8;
}

@media (max-width: 992px) {
  .profile-picture {
    width: 170px;
    height: 170px;
    transform: translateX(-50%);
  }
  .upload-btn {
    width: 50px;
    height: 50px;
    font-size: 30px;
    line-height: 20px;
  }
  .profile-picture .upload-btn{
    right: 43px;
    bottom: 5px;
  }
  .banner .upload-btn {
    right: 20px;
    top: 20px;
  }
  .mini-navbar ul {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    margin-left: 0px;
  }
  
  .mini-navbar li {
    display: inline-block;
    margin-right: 10px;
  }
  .players-container {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    max-width: 100%;
    overflow: auto;
  }
  
  .team-member {
    font-size: 14px;
    margin-bottom: 15px;
    flex-basis: calc(50% - 20px);
    box-sizing: border-box;
    margin-right: 10px;
  }
  
  .member-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }  

  .membresia-label {
    display: none;
  }

  .nombre-real, .nickname, .membresia {
    display: block;
    margin: 10px 0
  }

  .playerSelector label {
    margin: 10px 0;
    margin-left: 0
  }

  .playerSelector, .invite-container {
    width: 100%;
    margin-left: 0;
  }

  .inviteButton {
    width: 100%;
    margin-top: 10px;
  }
}
