.social-icons {
  font-size: 30px;
  color: white;
  margin-right: 100px;
  display: flex;
  gap: 10px;
}

.social-icons a {
  color: white;
  text-decoration: none;
}

.social-icons-top {
  display: flex;
  font-size: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 5px;
  gap: 10px;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 15px;
  background-color: #b21919;
  color: white;
  height: 80px;
}

.Header {
  height: 90px;
  position: relative;
  display: flex;
  background-color: #b21918;
}
.Header-top {
  background-color: #000000;
}

body {
  background-image: url('/src/images/img-home/web_foto_patron.png');
  background-position: center;
  background-size: auto;
  background-repeat: repeat;
}

.Header-main .nav-link {
  color: white;
  height: 90;
  margin-right: 15px;
}

.dropdown-menu {
  display: none;
}

.nav-item:hover .dropdown-menu {
  display: block;
  background-color: black;
}

.navbar-nav a:hover {
  color: #e30d13;
  background-color: black;
}
.navbar-nav a:hover,
.navbar-nav .dropdown:hover .nav-link {
  color: #e30d13;
}
.navbar-nav a {
  color: white;
}

.text-center {
  padding-top: 12px;
}

.navbar-brand {
  margin-left: 100px;
}

.navbar-nav {
  margin-right: 100px;
}

.Registro, 
.Acceso,
.Perfil {
  border: 1px solid white;
  background-color: transparent;
  color: white;
  outline: none;
  text-align: center;
  white-space: nowrap;
  padding: 1px 20px;
}
.Perfil{
  margin-right: 10px;
  margin-left: 5px;
  font-size: 13px;
}
.Acceso {
  margin-right: 10px;
  margin-left: 5px;
  font-size: 13px;
}
.Logout {
  color: white;
  font-size: 20px;
  margin-right: 15px;
  margin-left: 5px;
}
.Registro {
  margin-left: 13px;
  margin-right: 5px;
  font-size: 13px;
}

.Selector-idiomas {
  margin-right: 10px;
  font-size: 15px;
}

.text-footer-container p{
  text-align: center;
  padding-top: 15px;
}

.logo-container{
  margin-left: 150px;
}

.navbar > .nav-toggle{
  display: none;
}

@media only screen and (max-width: 992px){
  .navbar-nav{
    position:fixed;
    top: 80px;
    display: flex;
    flex-direction: column;
    background-color: #b21919;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    transition: all .45s;
    padding-top: 25px;
    z-index: 2;
    font-size: 20px;
  }
  .navbar-nav {
    margin-top: 20px;
  }
  .navbar-nav > a,
  .nav-item.dropdown {
    margin-left: 30px;
  }

  .navbar-nav > .nav-link > a::before{
    background: transparent;
  }
  .navbar-nav a:hover {
    color: #e30d13;
    background-color: transparent;
  }
  .navbar {
    background-image: none !important;
    background-color: #b21919;
    height: 80px;
  }

  .Header-main .navbar-brand img {
    height: 120px;
    position: relative;
    margin-left: -100px;
    padding-bottom: 70px;
  }

  .navbar-nav.open{
    transform: translateX(0);
  }

  .navbar-nav .dropdown:hover .nav-link {
  color: #5B0E07;
  }

  .nav-item:hover .dropdown-menu {
    display: block;
    background-color: black;
    width: 70%;
  }

  .navbar > .nav-toggle{
    display: flex;
    width: 50ox;
    height: 50px;
    padding-bottom: 70px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .nav-toggle > .bar{
    position: relative;
    width: 32px;
    height: 2px;
    background-color: white;
  }
  .nav-toggle > .bar::before, .nav-toggle > .bar::after{
    content: "";
    position: absolute;
    height: 2px;
    background: white;
    border-radius: 2px;
    transition: all .45s ease-in-out;
  }

  .nav-toggle > .bar::before{
    width: 25px;
    transform: translateY(-8px);
    right: 0;
  }

  .nav-toggle > .bar::after{
    width: 32px;
    transform: translateY(8px);
  }
  .nav-toggle.open > .bar{
    transform: translateX(-40px);
    background: transparent;
  }
  .nav-toggle.open > .bar::before{
    width: 32px;
    transform: rotate(45deg) translate(26px, -26px);
  }

  .nav-toggle.open > .bar::after{
    transform: rotate(-45deg) translate(26px, 26px);
  }

  .Registro, 
  .Acceso,
  .Perfil {
    padding: 0px 3px;/* Importar la fuente Montserrat desde Google Fonts */
    @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
    
    /* Establecer Montserrat como la fuente predeterminada para todo el cuerpo del documento */
    body {
      margin: 0;
      font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    
    code {
      font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
    }
    
    /* Ejemplo de uso de Montserrat con un peso específico y estilo */
    .montserrat-light {
      font-family: "Montserrat", sans-serif;
      font-weight: 300; /* Puedes cambiar esto según necesites */
      font-style: normal;
    }
    
    .montserrat-bold {
      font-family: "Montserrat", sans-serif;
      font-weight: 700; /* Puedes cambiar esto según necesites */
      font-style: normal;
    }
    
  }
}

@media only screen and (max-width: 768px) {
  .footer-container {
    padding: 20px;
    flex-direction: column;
    height: auto;
  }
  .logo-container{
    margin-left: 0px;
  }
  .social-icons{
    margin-right: 0px;
  }
  .social-icons-top{
    gap: 10px;
    display: none;
  }
}

.no-scroll {
  overflow: hidden;
}