.contenedor-home-principal .home1{
    padding-top: 100px;
    padding-left: 100px;
}
.home-background1 {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
  }
  .home1 h1{
    font-size: 75px;
    color: white;
    margin-bottom: 80px;
  }
  .home1 h2 {
    font-size: 55px;
    color: white;
    margin-bottom: 50px;
  }
  .home1 p{
    font-size: 30px;
    color: white;
    width: 50%;
    margin-bottom: 50px;
  }

  .button-reserva {
    font-size: 35px;
    display: inline-block;
    padding: 10px 50px;
    background-color: red;
    color: white;
    border: 2px solid transparent;
    border-radius: 0px;
    text-decoration: none;
    transition: opacity 0.3s ease;
    margin-bottom: 50px;
  }
  
  .button-reserva:hover {
    background-color: darkred;
  }

  .countdown {
    display: flex;
    align-items: center;
    gap: 20px;
    color: white;
  }
  
  .countdown > div {
    text-align: center;
  }
  
  .countdown span:first-child {
    display: block;
    font-size: 50px;
    margin-right: 40px;
  }
  
  .countdown span:last-child {
    font-size: 36px;
    margin-right: 40px;
  }
  
  .black-container{
    background-color: black;
    padding-bottom: 100px;
  }

  .black-container h2{
    color: #e30d13;
    text-align: center;
    padding-top: 200px;
    font-size: 40px;
  }
  .black-container p {
    color: white;
    font-size: 30px;
    text-align: center;
    margin-top: 100px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .inscribite-container{
    color: white;
    font-size: 30px;
    width: 50%;
    margin-left: 100px;
    margin-bottom: 100px;
    padding: 0 20px;
  }

  .inscribite-container h1{
    margin-top: 50px;
    margin-bottom: 30px;
  }

  .img-container {
    position: relative;
    overflow: hidden;
    float: right;
    left: 70%;
    width: 45%;
    margin-top: -54%;
  }
  
  .inscripcion{
    display: block;
    width: 100%;
  }
  
  .logo-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 80%;
    max-height: 80%;
  }

  .inscribirse-button {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 25px;
    background-color: red;
    color: white;
    border: none;
    padding: 10px 100px;
    cursor: pointer;
  }
  
  .inscribirse-button:hover {
    background-color: darkred;
  }
  
  .img-lower {
    width: 100%;
    height: auto;
  }

  .black-container-lower{
    background-color: black;
  }

  .black-container-lower p {
    color: white;
    font-size: 35px;
    text-align: center;
    padding: 80px;
    margin: 0;
  }

  @media (max-width: 992px) {
    .mobile-br {
      display: none;
    }
    .contenedor-home-principal .home1 {
      padding-top: 50px;
      padding-left: 20px;
      padding-right: 20px;
      text-align: center;
    }
  
    .home-background1 {
      background-size: cover;
    }
  
    .home1 h1, .home1 h2, .home1 p {
      font-size: 50px;
    }
  
    .home1 h1 {
      font-size: 32px;
      margin-bottom: 40px;
    }
  
    .home1 h2 {
      font-size: 24px;
      margin-bottom: 25px;
    }
  
    .home1 p {
      font-size: 16px;
      width: 100%;
      margin-bottom: 25px;
    }
  
    .countdown {
      justify-content: center;
      flex-direction: row;
      gap: 10px;
      padding-bottom: 40px;
    }
    .countdown span:first-child, .countdown span:last-child {
      font-size: 24px;
    }
    .button-reserva {
      font-size: 20px;
      padding: 10px 50px;
      border: 2px solid transparent;
    }
  
    .black-container h2 {
      color: #e30d13;
      text-align: center;
      padding-top: 70px;
      font-size: 24px; 
      padding-left: 20px;
      padding-right: 20px;
    }
    
    .black-container p {
      color: white;
      font-size: 18px;
      text-align: center;
      margin-top: 50px;
      padding-left: 20px;
      padding-right: 20px;
    }
    .inscribite-container {
      color: white;
      font-size: 20px;
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: auto;
    }
  
    .inscribite-container h1 {
      margin-top: 30px;
      margin-bottom: 20px;
      font-size: 24px;
    }
    .img-container {
      position: relative;
      width: 100%;
      margin-top: 40px;
      margin-bottom: 20px;
      left: auto;
      right: auto;
      float: none;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
    .black-container-lower p {
      font-size: 25px;
      padding: 40px;
  }
}
  